import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import MainLayout from '../../layouts/main';
import HomePage from '../../pages/HomePage';
import LegalNotice from '../../pages/legal-notice';
import PricavyPolicy from '../../pages/privacy-policy';
import TermsOfService from '../../pages/terms-of-service';
import { paths } from '../paths';
import ContactPage from '../../pages/ContactPage';
import AboutUs from '../../pages/AboutUs'

// ----------------------------------------------------------------------

const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      { path: '/', element: <HomePage /> },
      {
        path: paths.impressum,
        element: <LegalNotice />,
      },
      { path: paths.privacy, element: <PricavyPolicy /> },
      { path: paths.termsOfService, element: <TermsOfService /> },
      { path: paths.contact, element: <ContactPage /> },
      { path: paths.about, element: <AboutUs />}
    ],
  },
];
