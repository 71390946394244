import { m } from 'framer-motion';
import PropTypes from 'prop-types';
// @mui
import { Alert, Button, Stack, Typography } from '@mui/material';
// components
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { usePostHog } from 'posthog-js/react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useAuthContext } from '../../auth/hooks';
import { MotionViewport, varFade } from '../../components/animate';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { BACKEND_API_URL } from '../../config-global';

// ----------------------------------------------------------------------

ContactForm.propTypes = {
  heading: PropTypes.string,
  helpPage: PropTypes.bool,
};

export default function ContactForm({ heading, helpPage = false }) {
  const posthog = usePostHog();
  const ContactSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(200),
    email: Yup.string()
      .trim()
      .email('Email must be a valid email address')
      .required('Email is required'),
    subject: Yup.string().required('Subject is required').max(300),
    message: Yup.string().required('Message is required').max(10_000),
  });

  const { user } = useAuthContext();

  const methods = useForm({
    resolver: yupResolver(ContactSchema),
    defaultValues: {
      name: helpPage ? user?.displayName : '',
      email: helpPage ? user?.email : '',
      subject: helpPage ? 'Message from help page' : '',
      message: '',
    },
  });

  const {
    setError,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await axios.post(`${BACKEND_API_URL}contact/form`, data);
      posthog?.identify(data?.email, {
        email: data?.mail,
      });
      posthog?.capture('contactform');
      reset();
    } catch (error) {
      console.error(error);
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  return (
    <Stack component={MotionViewport} spacing={5}>
      <m.div variants={varFade().inUp}>
        <Typography variant="h3">
          {heading || (
            <>
              Feel free to contact us. <br />
              We&apos;ll be glad to hear from you.
            </>
          )}
        </Typography>
      </m.div>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
          {/* show message after submit */}
          {isSubmitSuccessful && (
            <Alert severity="success">
              Thank you for your message. We will respond to you as soon as possible.
            </Alert>
          )}
          {!helpPage && (
            <>
              <m.div variants={varFade().inUp}>
                <RHFTextField name="name" label="Name" />
              </m.div>
              <m.div variants={varFade().inUp}>
                <RHFTextField name="email" label="Email" />
              </m.div>
              <m.div variants={varFade().inUp}>
                <RHFTextField name="subject" label="Subject" />
              </m.div>
            </>
          )}
          <m.div variants={varFade().inUp}>
            <RHFTextField name="message" label="Enter your message here." multiline rows={4} />
          </m.div>
          <m.div variants={varFade().inUp}>
            <Button type="submit" size="large" variant="contained">
              Send message
            </Button>
          </m.div>
        </Stack>
      </FormProvider>
    </Stack>
  );
}
