import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// sections
import AboutUs from '../sections/home/AboutUs';
import { MotionContainer } from '../components/animate';
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function HomePage() {
  const isDesktop = useResponsive('up', 'md');
  const sx = {};
  if (isDesktop) {
    sx.padding = 10;
  } else {
    sx.padding = 2;
    sx.paddingTop = 10;
  }

  return (
    <>
      <Helmet>
        <title> Detect Deepfakes | Detesia </title>
      </Helmet>

      <Container component={MotionContainer} sx={sx}>
        <AboutUs />
      </Container>
    </>
  );
}
