import { Container, Typography } from '@mui/material';

export default function PricavyPolicy() {
  return (
    <Container textAlign="center">
      <Typography
        variant="h1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
          fontSize: 'clamp(1rem, 5vw, 2rem)',
        }}
      >
        Datenschutzbestimmungen
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Allgemeiner Hinweis und Pflichtangaben
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Benennung der verantwortlichen Stelle
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Der Verantwortliche für die Datenverarbeitung auf dieser Website ist:
        <br />
        Detesia GbR
        <br />
        entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
        personenbezogener Daten (z.B. Namen, Kontaktdaten, etc.).
      </Typography>

      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Einige Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich.
        Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf
        ist eine formlose Mitteilung per E-Mail ausreichend. Die Rechtmäßigkeit der bis zum Widerruf
        erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </Typography>

      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Als Betroffener haben Sie das Recht, sich im Falle eines Verstoßes gegen
        datenschutzrechtliche Bestimmungen bei der zuständigen Aufsichtsbehörde zu beschweren. Die
        zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
        Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat.
        Unter folgendem Link finden Sie eine Liste der Datenschutzbeauftragten und deren
        Kontaktdaten:
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
      </Typography>

      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Recht auf Datenübertragbarkeit
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Sie haben das Recht, dass Daten, die wir auf der Grundlage Ihrer Einwilligung automatisch
        verarbeiten oder in Erfüllung eines Vertrages verarbeiten, an Sie oder an Dritte
        herauszugeben. Die Daten werden in einem maschinenlesbaren Format zur Verfügung gestellt.
        Wenn Sie die direkte Übermittlung der Daten an eine andere verantwortliche Stelle, erfolgt
        dies nur, soweit es technisch möglich ist.
      </Typography>

      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Recht auf Auskunft, Berichtigung, Sperrung, Löschung
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen das Recht, jederzeit jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
        Herkunft Daten, deren Empfänger und den Zweck der Datenverarbeitung sowie ggf. ein Recht auf
        Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich sowie zu weiteren Fragen
        zum Thema zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum
        aufgeführten Kontaktmöglichkeiten Impressum aufgeführten Kontaktmöglichkeiten.
      </Typography>

      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        TLS-Verschlüsselung
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie aus
        Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als
        Seitenbetreiber senden, verwendet unsere Website eine SSL- oder TLS-Verschlüsselung. Dies
        bedeutet, dass Daten die Sie über diese Website übermitteln, nicht von Dritten gelesen
        werden können. Sie erkennen eine verschlüsselte Verbindung erkennen Sie an der
        &quot;https://&quot; Adresszeile Ihres Browsers und dem Schloss Symbol im Browser.
      </Typography>

      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Server-Protokolldateien
      </Typography>
      <Typography
        variant="body1"
        sx={{
          my: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        In Server-Protokolldateien sammelt und speichert der Anbieter der Website automatisch
        Informationen, die Ihr Browser automatisch an uns sendet. Diese sind:
        <br />
        <br />
        Besuchte Seite auf unserer Domain
        <br />
        Datum und Uhrzeit der Serveranfrage
        <br />
        Browsertyp und -version
        <br />
        Betriebssystem
        <br />
        Referrer URL
        <br />
        Hostname des zugreifenden Computers
        <br />
        IP-Adresse
        <br />
        <br />
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zwecks ihrer Erhebung nicht
        mehr benötigt werden. Im Falle der Datenerhebung zur Bereitstellung der Website wird dies
        nach Beendigung der jeweiligen Sitzung durchgeführt. Sollten Daten in Logdateien gespeichert
        werden, werden diese spätestens nach sieben Tagen gelöscht.
        <br />
        Diese Daten werden nicht mit anderen Datenquellen zusammengeführt. Die Grundlage für die
        Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
        Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
        <br />
        Die Website wird auf Servern von Netlify, Inc., 2325 3rd St #215, San Francisco, Kalifornien
        94107, USA gehostet. Die Server befinden sich in den Vereinigten Staaten.
        <br />
      </Typography>

      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Benutzeranalyse über PostHog
      </Typography>
      <Typography
        variant="body1"
        sx={{
          my: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Wir verwenden PostHog für die Nutzeranalyse, um das Nutzerverhalten zu verstehen und unsere
        Website zu verbessern. PostHog sammelt Daten wie besuchte Seiten, die auf jeder Seite
        verbrachte Zeit und die auf der Website durchgeführten Aktionen. Diese Daten werden
        anonymisiert und aggregiert zu Analysezwecken.
        <br />
        <br />
        Die Verwendung von PostHog basiert auf Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von
        Daten für die Zwecke der berechtigten Interessen, die vom Verantwortlichen verfolgt werden,
        erlaubt, sofern diese Interessen nicht durch die Interessen oder Grundrechte und
        Grundfreiheiten der betroffenen Person überwiegen.
        <br />
        <br />
        Wenn Sie nicht möchten, dass Ihre Daten von PostHog gesammelt werden, können Sie dies über
        Ihre Browsereinstellungen ablehnen oder durch die Verwendung von verfügbaren
        Datenschutztools.
        <br />
      </Typography>
    </Container>
  );
}
