import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import App from './App';

import { REACT_APP_PUBLIC_POSTHOG_KEY } from './config-global';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

if (
  !(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1' ||
    localStorage.getItem('developer') !== null
  )
) {
  posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: 'https://eu.posthog.com',
    persistence: 'localStorage',
  });
}

root.render(
  <PostHogProvider client={posthog}>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </PostHogProvider>
);
