import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { m } from 'framer-motion';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { alpha, useTheme } from '@mui/material/styles';
import { varFade } from '../../../components/animate';

export default function WhatMakesUsSpecial() {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  const shadow = `-10px 20px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  const cardContents = [
    {
      avatar: <SettingsSuggestIcon sx={{ fontSize: '3rem' }} />,
      title: 'Multi Detector',
      text: 'Our architecture leverages a multi-modal approach to scrutinize every detail including biological traits, voice, and behavior enhancing the accuracy of deepfake detection through a fusion of sophisticated algorithms.',
    },
    {
      avatar: <RocketLaunchIcon sx={{ fontSize: '3rem' }} />,
      title: 'Latest Research',
      text: "At the forefront of innovation, we're constantly refining our methods to stay ahead in the fast-paced world of deepfake technology, ensuring our solutions are as dynamic as the threats they combat.",
    },
    {
      avatar: <PersonSearchIcon sx={{ fontSize: '3rem' }} />,
      title: 'Explainability',
      text: 'Our technology stands out for its ability to transparently differentiate real from synthetic identities, offering insights into the detection process and the rationale behind each decision.',
    },
  ];

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: 'center',
            marginY: 'auto',
            fontWeight: 'bold',
            background: (tm) =>
              `-webkit-linear-gradient(260deg, ${tm.palette.primary.main} 0%, ${tm.palette.grey[800]} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          What Makes Us Special
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={3}>
          {cardContents.map((card, index) => (
            <Grid item md={12} lg={4} sx={{ display: 'flex' }} key={index}>
              <m.div variants={varFade().inRight} style={{ flex: '1' }}>
                <Card
                  justifyContent="center"
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 2,
                    boxShadow: shadow,
                    filter: 'grayscale(100%)',
                    '&:hover': { filter: 'grayscale(0%)', transform: 'scale(1.05)' },
                  }}
                >
                  <CardHeader
                    avatar={card.avatar}
                    title={card.title}
                    titleTypographyProps={{ sx: { fontSize: '1.5rem', fontWeight: 'bold' } }}
                    sx={{
                      marginY: 'auto',
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      background: (tm) =>
                        `-webkit-linear-gradient(260deg, ${tm.palette.primary.main} 0%, ${tm.palette.grey[800]} 100%)`,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  />
                  <CardContent>
                    <Typography sx={{ textAlign: 'center' }}>
                      {card.text}
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </m.div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
