import {Card, Grid, Typography} from "@mui/material";
import React from "react";

const gradientTextStyle = {
    fontWeight: 'bold',
    background: '-webkit-linear-gradient(260deg, #007bff 0%, #000 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '1.15',
};

const missionSection = [
    {
        img: '/assets/images/about/team.webp',
        alt: 'Descriptive Alt Text',
        title: 'Revolutionizing trust in the digital age',
        text: 'At Detesia, we are dedicated to pioneering deepfake detection solutions responsibly. Our mission is to provide cutting-edge solutions that are not only effective but also explainable. We strive to empower users with the tools to identify and understand digital deception, ensuring authenticity and trust in the digital world.',
    },
    {
        img: '/assets/images/about/cispa.webp',
        alt: 'Descriptive Alt Text',
        title: 'From Leading Research to Real-World Impact',
        text: 'In the course of our studies at the Saarland University and the CISPA Helmholtz Center for IT Security, we developed our first prototype for deepfake detection. We successfully transitioned from an academic project into a startup. As a spin-off, we maintain active engagement with leading scientists.',
    },
];

export default function Storyline() {
    return (
        <Grid container spacing={7}>
            {missionSection.map((item, index) => (
                    <Grid item xs={12}>
                        <Card sx={{width: '100%', height: '100%'}}>
                            <Grid container>
                                <Grid item lg={7} order={{xs: (index % 2) + 1}}>
                                    <img src={item.img} alt={item.alt} style={{width: '100%', height: 'auto'}}/>
                                </Grid>
                                <Grid item lg={5} order={{xs: 2 - (index % 2)}}>
                                    <div style={{margin: 30}}>
                                        <Typography variant="h3" style={gradientTextStyle}>
                                            {item.title}
                                        </Typography>
                                        <Typography style={{paddingTop: '30px'}}>{item.text}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
}


