import { Grid, Typography } from '@mui/material';
import Storyline from "./aboutUs/Storyline";
import Team from "./aboutUs/Team";
import Partners from "./landingPage/Partners";

// ----------------------------------------------------------------------

const gradientTextStyle = {
    fontWeight: 'bold',
    background: '-webkit-linear-gradient(260deg, #007bff 0%, #000 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '1.15',
};

const headingStyle = {
    ...gradientTextStyle,
    textAlign: 'center',
};

export default function AboutUs() {
    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <Typography variant="h1" style={{ ...headingStyle, mb: 20 }}>
                    About Us
                </Typography>
            </Grid>

            <Grid item>
                <Grid container spacing={20}>
                    <Grid item>
                        <Storyline />
                    </Grid>

                    <Grid item>
                        <Team />
                    </Grid>

                    <Grid item>
                        <Partners />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
