import { Container, Typography } from '@mui/material';

export default function TermsOfService() {
  return (
    <Container textAlign="center">
      <Typography
        variant="h1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
          fontSize: 'clamp(1rem, 5vw, 2rem)',
        }}
      >
        Terms of Service
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Diese Nutzungsbedingungen gelten für die Nutzung der Website unter der Domain detesia.com
        (nachfolgend „Website“ genannt) durch den Nutzer. Sie enthalten die Regeln und Bestimmungen
        für die Nutzung von Detesia Website unter https://detesia.com.
        <br />
        <br />
        Wir gehen davon aus, dass Sie bei Zugriff auf die Website diese Nutzungsbedingungen gelesen
        haben und diese in vollem Umfang akzeptieren. Bitte fahren Sie mit der Nutzung der Website
        nicht fort, wenn Sie mit diesen Nutzungsbedingungen nicht einverstanden sind.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Cookies
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Die Website verwendet Cookies, um Ihr Online-Erlebnis zu personalisieren. Durch weiteren
        Zugriff auf Detesia stimmen Sie der Verwendung von Cookies zu.
        <br />
        <br />
        Ein Cookie ist eine Textdatei, die von einem Webserver auf Ihrer Festplatte gespeichert
        wird. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie
        enthalten einen eindeutigen Identifikator und können nur von dem Webserver der Domain
        gelesen werden, die das Cookie bei Ihnen gespeichert hat.
        <br />
        <br />
        Wir verwenden Cookies zu unterschiedlichen Zwecken, unter anderem zur Analyse und für
        personalisierte Marketing-Mitteilungen mit dem Ziel, die Benutzerfreundlichkeit unserer
        Website zu verbessern. Sie haben die Möglichkeit, optionale Cookies abzulehnen. Einige
        Cookies sind jedoch für das einwandfreie und ordnungsgemäße Funktionieren unserer Website
        unbedingt notwendig. Solche technisch notwendigen Cookies müssen immer aktiviert werden und
        erfordern daher keiner Einwilligung. Beachten Sie bitte, dass Sie durch das Akzeptieren der
        erforderlichen Cookies auch Cookies von Drittanbietern akzeptieren, sofern Sie von
        Drittanbietern auf unserer Website bereitgestellte Dienste wie z. B. ein integriertes
        Videoanzeigefenster nutzen.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Lizenz
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Sofern nicht anderweitig angegeben haben Detesia und/oder seine Lizenzgeber die geistigen
        Eigentumsrechte für alle Materialien auf Detesia. Alle Rechte am geistigen Eigentum sind
        vorbehalten. Sie können Seiten von Detesia für Ihren eigenen persönlichen Gebrauch ansehen
        und/oder ausdrucken, vorbehaltlich der in diesen Allgemeinen Geschäftsbedingungen
        festgelegten Einschränkungen.
        <br />
        <br />
        Sie stimmen zu, dass Sie Folgendes nicht tun oder versuchen dürfen:
        <br />
        <br />
        <ul style={{ paddingLeft: '20px' }}>
          <li>
            Den Dienst in einer Weise zu nutzen, die gegen geltende lokale, nationale oder
            internationale Gesetze oder Vorschriften verstößt.
          </li>
          <li>
            Den Dienst in einer Weise zu nutzen, die die Rechte oder Interessen von Detesia, der
            Community oder Dritten verletzen könnte, z. B. um ein Konzept zu beweisen oder zu
            widerlegen oder einen Akteur im Anti-Deepfake-Bereich zu diskreditieren oder zu ködern.
          </li>
          <li>
            Material, Informationen oder Funktionen des Dienstes zu kopieren, zu reproduzieren, zu
            verändern, zu modifizieren, abgeleitete Werke zu erstellen, öffentlich auszustellen, neu
            zu veröffentlichen, hochzuladen, zu posten, zu übertragen, weiterzuverkaufen oder in
            irgendeiner Weise zu verbreiten - einschließlich, aber nicht beschränkt auf die
            Verwendung des Dienstes in irgendeiner Weise für Deepfake-Erkennungs-Tests oder die der
            Deepfake-Erkennungs-Branche direkt oder indirekt schaden, mit ihr konkurrieren oder sie
            anderweitig behindern könnte.
          </li>
          <li>Übertragung von Rechten, die Ihnen im Rahmen der Bedingungen gewährt werden.</li>
          <li>
            Den Service für illegale Aktivitäten oder Ausgaben zu nutzen oder in einer Weise, die
            Detesia, Sie, Mitglieder der Community oder unsere Partner einem Schaden aussetzt.
          </li>
          <li>
            Sich an Aktivitäten zu beteiligen, die das normale Funktionieren des Dienstes
            beschädigen, überlasten, beeinträchtigen oder behindern könnten.
          </li>
          <li>
            Proben zu beziehen oder zu verwenden, es sei denn, dies ist im Rahmen des Dienstes
            ausdrücklich gestattet, oder den Dienst zu verwenden oder zu versuchen, ihn zu
            verwenden, um Informationen in irgendeiner Weise zu sammeln, die einzelne Personen in
            ihrer privaten Eigenschaft identifizieren könnten, zu versuchen, auf die in einer Probe
            enthaltenen Inhalte zuzugreifen oder diese zu missbrauchen, oder den Dienst oder die
            Proben in anderer Weise zu einem anderen Zweck zu verwenden als zur Erkennung und
            Verhinderung von Schadsoftware in einer nicht kommerziellen persönlichen oder
            organisatorischen Eigenschaft.
          </li>
          <li>
            Der Versuch, sich unbefugten Zugang zu unserer Website, dem Server, auf dem unsere
            Website gespeichert ist, oder einem mit unserer Website verbundenen Server, Computer
            oder einer Datenbank zu verschaffen.
          </li>
          <li>
            Unsere Website durch einen Denial-of-Service-Angriff oder einen verteilten
            Denial-of-Service-Angriff anzugreifen oder dies zu versuchen.
          </li>
          <li>
            Den Dienst zu nutzen, um Deepfakes zu erstellen, zu veröffentlichen oder zu verbreiten.
          </li>
        </ul>
        <br />
        <br />
        Diese Vereinbarung tritt mit dem heutigen Datum in Kraft.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Sichere Teilhabe
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Sie müssen mindestens 18 Jahre alt sein, um den Service zu nutzen. Sie erklären und
        garantieren, dass Sie mindestens 18 Jahre alt und volljährig sind, um einen verbindlichen
        Vertrag abzuschließen.
        <br />
        <br />
        Detesia bietet bestimmte Funktionen an, die die Erstellung eines persönlichen Kontos
        erfordern können. Sie verpflichten sich, uns genaue, vollständige und aktualisierte
        Registrierungsinformationen zur Verfügung zu stellen. Abhängig von den bei der Registrierung
        und Profilerstellung angegebenen Informationen kann Ihr Konto Sie eindeutig identifizieren.
        Jeder Kommentar, Beitrag oder jede andere Aktivität, an der Sie sich in der Community
        beteiligen, wenn Sie Ihr Konto nutzen, erzeugt Inhalte, die Ihnen zugeordnet werden
        (&quot;Inhalte&quot;).
        <br />
        <br />
        Soweit Sie Passwörter/Schlüssel/Zugangsdaten für den Zugriff auf den Dienst verwenden, sind
        Sie ausschließlich dafür verantwortlich, eindeutige und komplexe Zugangsdaten zu erstellen,
        diese zu schützen und für alle Aktivitäten oder Handlungen, die unter Verwendung dieser
        Zugangsdaten im Dienst vorgenommen werden. Sie dürfen Ihr Konto nicht an eine andere Person
        übertragen.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Verlinken unserer Inhalte
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Folgende Institutionen dürfen ohne vorherige schriftliche Zustimmung einen Link auf unsere
        Website erstellen:
        <br />
        <br />
        <ul style={{ paddingLeft: '20px' }}>
          <li>Regierungsbehörden;</li>
          <li>Suchmaschinen;</li>
          <li>Nachrichtenorganisationen;</li>
          <li>
            Online-Verzeichnisse dürfen in ihrer Auflistung einen der anderen dort gelisteten
            Unternehmen entsprechenden Link zu unserer Website erstellen.
          </li>
        </ul>
        <br />
        <br />
        Diese Organisationen dürfen eine Verlinkung zu unserer Startseite erstellen, sofern die
        Verlinkung: (a) in keiner Weise irreführend ist; (b) nicht irrtümlicherweise Förderung,
        Unterstützung oder Zustimmung in Bezug auf die verlinkenden Partei oder ihre Produkte
        und/oder Dienstleistungen andeutet; und (c) in den Kontext der zu verlinkenden Website
        passt.
        <br />
        <br />
        Wenn Sie eine Verlinkung anfragen, senden Sie uns bitte eine E-Mail an contact@detesia.com
        unter Angabe Ihres Namens, des Namens Ihrer Organisation, Ihrer Kontaktdaten, Ihrer
        Website-URL, der Liste aller URLs, von denen Sie auf unsere Website verlinken möchten sowie
        der Liste aller URLs auf unserer Website auf die Sie verlinken möchten. Wir werden uns
        bemühen, Ihnen innerhalb von 2 bis 3 Wochen zu antworten.
        <br />
        <br />
        Wir übernehmen keine Haftung für Inhalte, die auf Ihrer Website erscheinen. Sie erklären
        sich hiermit einverstanden, uns hinsichtlich aller auf Ihrer Website erhobenen Ansprüche
        schadlos zu halten. Auf keiner Website dürfen Links erscheinen, deren Inhalte als
        verleumderisch, obszön oder kriminell gewertet werden können oder gegen Rechte verstoßen,
        sie verletzen oder sonstige Verstöße und Verletzung von Rechten Dritter befürworten. Wir
        behalten uns das Recht vor, jederzeit und nach eigenem Ermessen zu verlangen, dass Sie alle
        Links oder einen bestimmten Link zu unserer Website entfernen. Sie erklären sich damit
        einverstanden, einer solchen Aufforderung umgehend nachzukommen und alle Links zu unserer
        Website unverzüglich zu entfernen.
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Haftungsausschluss
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 5,
          fontWeight: 'fontWeightMedium',
        }}
      >
        Soweit dies nach geltendem Recht zulässig ist, schließen wir alle Zusicherungen,
        Gewährleistungen und Bedingungen im Zusammenhang mit unserer Website und der Nutzung dieser
        Website aus. ^ Nichts in diesem Haftungsausschluss wird:
        <br />
        <br />
        <ul style={{ paddingLeft: '20px' }}>
          <li>
            Die Haftung für Tod oder Körperverletzung einschränken oder ausschließen, die sich aus
            Fahrlässigkeit oder Betrug ergeben.
          </li>
          <li>
            Die Haftung für Betrug oder betrügerische Falschdarstellung einschränken oder
            ausschließen.
          </li>
          <li>
            Die Haftung in einer Weise ausschließen, die nach geltendem Recht nicht ausgeschlossen
            werden darf.
          </li>
          <li>Die Haftung für vernachlässigbare Fahrlässigkeit ausschließen oder beschränken.</li>
          <li>
            Die Haftung für die Verletzung einer gesetzlichen Pflicht ausschließen oder beschränken.
          </li>
        </ul>
        <br />
        <br />
        Die in diesem Abschnitt und an anderer Stelle in diesem Haftungsausschluss dargelegten
        Haftungsbeschränkungen und -ausschlüsse: (a) unterliegen dem vorhergehenden Absatz und (b)
        gelten für alle Haftungen, die sich aus dem Haftungsausschluss oder in Bezug auf den
        Gegenstand dieses Haftungsausschlusses ergeben, einschließlich Haftungen aus Vertrag,
        unerlaubter Handlung und aufgrund der Verletzung gesetzlicher Pflichten.
        <br />
        <br />
        Solange die Website und die Informationen und Dienstleistungen auf dieser Website kostenlos
        zur Verfügung gestellt werden, übernehmen wir keine Haftung für Verluste oder Schäden
        jeglicher Art.
        <br />
        <br />
        Wir übernehmen keine Haftung für die Aussage, dass die auf dieser Website veröffentlichten
        Informationen korrekt, vollständig oder aktuell sind.
        <br />
        <br />
      </Typography>
    </Container>
  );
}
