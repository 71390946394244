import Iconify from '../../../components/iconify';
import { paths } from '../../../routes/paths';

// ----------------------------------------------------------------------

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'About',
    icon: <Iconify icon="eva:book-open-fill" />,
    path: paths.about,
  },
  {
    title: 'Login',
    icon: <Iconify icon="eva:person-fill" />,
    path: paths.dashboard.upload,
  },
];

export default navConfig;
