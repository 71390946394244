import { Container, Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from '../routes/paths';

export default function LegalNotice() {
  return (
    <>
      <Helmet>
        <title> Impressum | Detesia </title>
      </Helmet>
      <Container textAlign="center">
        <Typography
          variant="h1"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Impressum
        </Typography>
        <Typography
          variant="h4"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Angaben gemäß § 5 TMG
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Detesia GbR (GmbH Gründung geplant für Q1 2025)
        </Typography>
        <Typography
          variant="h4"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Gesellschafter
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Philipp Dewald
          <br />
          Peter Stolz
          <br />
          Tim Walita
        </Typography>
        <Typography
          variant="h4"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Addresse
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Mozartstraße 22, 66111 Saarbrücken
        </Typography>
        <Typography
          variant="h4"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Kontakt
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          contact[at]detesia.com
        </Typography>
        <Link
          variant="body1"
          to={paths.contact}
          component={RouterLink}
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Kontaktformular
        </Link>

        <Typography
          variant="h4"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Redaktionell Verantwortlicher
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Philipp Dewald
        </Typography>

        <Typography
          variant="h4"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Online-Streitbeilegung (ODR)
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Online-Streitbeilegung: Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (ODR) bereit, die Sie unter https://ec.europa.eu/consumers/odr/
          erreichen können. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung
          ihrer Streitigkeiten zu nutzen.
        </Typography>
        <Typography
          variant="h4"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Haftung für Inhalte
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 5,
            fontWeight: 'fontWeightMedium',
          }}
        >
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
          den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
          jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
          den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
          erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
          entfernen.
          <br />
          <br />
        </Typography>
      </Container>
    </>
  );
}
