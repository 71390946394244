import { Grid } from '@mui/material';
import WhatMakesUsSpecial from './landingPage/WhatMakesUsSpecial';
import Partners from './landingPage/Partners';
import MorganFreeman from './landingPage/MorganFreeman';
import MaillistPreview from '../../components/conversion/MaillistPreview';
import HomeAdvertisement from '../../components/conversion/HomeAdvertisement';

// ----------------------------------------------------------------------

export default function LandingPage() {
  return (
    <Grid container spacing={20}>
      <Grid item>
        <MorganFreeman />
      </Grid>

      <Grid item>
        <WhatMakesUsSpecial />
      </Grid>

      {/*
      <Grid item>
        <Partners />
      </Grid>
      */}

      <Grid item xs={12}>
        <HomeAdvertisement />
      </Grid>
    </Grid>
  );
}
